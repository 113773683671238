import React from 'react';
import { IntlProvider } from 'react-intl';
import { graphql } from 'gatsby';

import { isSalonzone } from '../utils';
import messages from '../data/messages';
import salonzoneMessages from '../data/messages/salonzone';
import BarberProfile from '../components/BarberProfile';
import FontSelector from '../components/FontSelector';

export const reviewsForBarber = graphql`
  query reviewsForBarber($barberId: Int!) {
    allReview(
      sort: { fields: [review_timestamp], order: DESC }
      limit: 6
      filter: { employee_id: { eq: $barberId } }
    ) {
      edges {
        node {
          reviewId
          review_feedback
          review_stars
          review_timestamp
          customer_initials
          employee_id
          service_id
          shop_id
          service {
            title
          }
        }
      }
    }
  }
`;

// TODO: use same wrapper component for page with hero image
const BarberProfileTemplate = ({
  pageContext: { shopDetails, barberDetails },
  data: {
    allReview: { edges: reviews },
  },
}) => {
  const locale =
    shopDetails && shopDetails.country && shopDetails.country.language_key;

  return (
    <IntlProvider
      locale={locale}
      messages={isSalonzone ? salonzoneMessages[locale] : messages[locale]}
    >
      <FontSelector>
        <BarberProfile
          reviews={reviews}
          barberDetails={barberDetails}
          shopDetails={shopDetails}
        />
      </FontSelector>
    </IntlProvider>
  );
};

export default BarberProfileTemplate;
