import React, { useState, useCallback } from 'react';

import { Box, Typography, Container, IconButton } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';

import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

// https://neptunian.github.io/react-photo-gallery/

const Portfolio = ({ barberDetails }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const photos =
    (barberDetails &&
      barberDetails.portfolio_images &&
      barberDetails.portfolio_images.map((x) => ({
        src: `https://agrddpxdbm.cloudimg.io/v7/${x.image}?w=600&h=600&func=bound`,
        width: x.width,
        height: x.height,
      }))) ||
    [];

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return photos.length > 0 ? (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h4" align="center">
          Portfólió
        </Typography>
        {barberDetails.instagram_link ? (
          <IconButton
            aria-label="Instagram"
            size="small"
            onClick={() =>
              !!window &&
              window.open(
                `https://www.instagram.com/${barberDetails.instagram_link}`,
                '_blank',
              )
            }
          >
            <InstagramIcon fontSize="large" color="primary" />
          </IconButton>
        ) : null}
      </Box>

      <Gallery photos={photos} onClick={openLightbox} direction="row" />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  ) : null;
};

export default Portfolio;
