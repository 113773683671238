import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Box, Grid, Container, Divider } from '@material-ui/core';

import SEO from './seo';

import BookingHero from './BookingHero';
import Contact from './Contact';
import Reviews from './Reviews';
import Portfolio from './Portfolio';

import OpeningHours from './OpeningHours';
import BookingFooter from './BookingFooter';
import SecondaryButton from './SecondaryButton';

const PaddedGrid = styled(Grid)`
  && {
    padding: 2rem 0rem;
  }
`;

const BarberProfile = ({ reviews, barberDetails, shopDetails }) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <SEO
        title={`${barberDetails.display_name} | Profil`}
        description={shopDetails.meta_description}
        thumbnailPreview={shopDetails.hero_image}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        minHeight="100vh"
      >
        <Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <BookingHero
                profileImageSrc={barberDetails.profile_image}
                heroImageSrc={shopDetails.hero_image}
                title={barberDetails.display_name}
                subTitle={[
                  ...new Set(
                    barberDetails.service_levels.map((x) => x.level.title),
                  ),
                ].join(', ')}
                ctaText={
                  shopDetails.disable_booking_pages
                    ? null
                    : f({ id: 'shopDetailsBookNow' })
                }
                ctaAction={() =>
                  navigate(`/booking/${shopDetails.slug}/${barberDetails.slug}`)
                }
              />
            </Grid>
            <PaddedGrid item xs={12}>
              <Portfolio barberDetails={barberDetails} />
            </PaddedGrid>
            {reviews.length >= 1 ? (
              <PaddedGrid item xs={12}>
                <Reviews
                  barberDetails={barberDetails}
                  reviews={reviews.map((x) => x.node)}
                  shopSlug={shopDetails.slug}
                  barberSlug={barberDetails.slug}
                  showMore
                />
              </PaddedGrid>
            ) : null}
          </Grid>
          <Box display="flex" justifyContent="center" mb={2}>
            <SecondaryButton
              onClick={() => navigate(`/shop/${shopDetails.slug}`)}
              variant="outlined"
            >
              {f({ id: 'buttonBack' })}
            </SecondaryButton>
          </Box>
        </Box>
        <Box>
          <Divider />
          <Box mt={4}>
            <Container maxWidth="md">
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Contact shopDetails={shopDetails} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OpeningHours openingHours={shopDetails.opening_hours} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box mt={2}>
            <BookingFooter />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BarberProfile;
